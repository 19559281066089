import "./financialAid.css";

import * as Dialog from "@radix-ui/react-dialog";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { FinAidYearsResponse } from "types/FinAidYearsResponse";
import { FinancialAidDecisionDataResponse } from "types/FinancialAidDecisionDataResponse";
import { FinancialAidDetailsResponse } from "types/FinancialAidDetailsResponse";

// Fetch Financial Aid Year data
interface FinAidYear {
  AidYear: string;
  AidYearDescr: string;
  Institution?: string;
}

const FafsaStatus = () => {
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const [subheader, setSubHeader] = useState("");
  const [showlink, setShowlink] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const urlHostName = sessionStorage.getItem("urlHostName");
  const urlQAT = "portalqat.umgc.edu";
  const urlSTG = "portalstg.umgc.edu";
  const urlPROD = "students.umgc.edu";
  const portalQAT =
    "https://awscsint-sso.psdev.umgc.edu/psc/csintsso/EMPLOYEE/SA/c/SFA_STUDENT_FL.SFA_SS_START_PG_FL.GBL?GMenu=SFA_STUDENT_FL&GComp=SFA_SS_START_PG_FL&GPage=SFA_SS_START_PG_FL";
  const portalSTG =
    "https://awscsuat-sso.psdev.umgc.edu/psc/csuatsso/EMPLOYEE/SA/c/SFA_STUDENT_FL.SFA_SS_START_PG_FL.GBL?GMenu=SFA_STUDENT_FL&GComp=SFA_SS_START_PG_FL&GPage=SFA_SS_START_PG_FL";
  const portalPROD =
    "https://pshr.ps.umgc.edu/psc/uuccsprd/EMPLOYEE/SA/c/SFA_STUDENT_FL.SFA_SS_START_PG_FL.GBL?GMenu=SFA_STUDENT_FL&GComp=SFA_SS_START_PG_FL&GPage=SFA_SS_START_PG_FL";
  const [showDialog, setShowDialog] = useState(false);
  const [escPressed, setEscPressed] = useState(false);
  const text = "A new tab will open to display this page";

  const handleClick = (e: any = null) => {
    e.preventDefault();
    setShowDialog(true);
    document.getElementById("financialAidLink")?.blur();
    document.body.style.overflow = "hidden";
  };
  const close = (e: any) => {
    document.body.style.overflow = "auto";
    document.getElementById("financialAidLink")?.blur();
    setShowDialog(false);
  };

  const OK = (e: any, _url: string) => {
    window.open(_url, "_blank", "noreferrer");
    document.body.style.overflow = "auto";

    setShowDialog(false);
    e.preventDefault();
  };

  const getFinancialAidYear = async () => {
    try {
      const getFinancialAidYearData = (await getDuService({
        service: "accountService",
        method: "getStudentFinAidYear",
      })) as FinAidYearsResponse;

      const data = getFinancialAidYearData;
      const FinAidYear = data.FinAidYearsResp.FinAidYears;
      if (FinAidYear.length > 0) {
        generateFafsaMessage(FinAidYear);
      }
    } catch (error) {
      console.error("Error fetching financial aid year:", error);
      return null;
    }
  };

  const getFinancialDecisionData = async (Year: string) => {
    try {
      const getFinancialDecisionDataStatus = (await getDuService(
        {
          service: "accountService",
          method: "getFinancialDecisionData",
        },
        Year
      )) as FinancialAidDecisionDataResponse;
    } catch (error) {
      console.error("Error fetching financial aid year:", error);
      return null;
    }
  };

  const getFinancialAidYearDesc = async (Year: string, Desc: string) => {
    try {
      const getFinancialAidYearDataStatus = (await getDuService(
        {
          service: "accountService",
          method: "getStudentFinAidYearStatus",
        },
        Year
      )) as FinancialAidDetailsResponse;

      if (
        getFinancialAidYearDataStatus.EFC_SAP_Status_Details.Step1_Text.indexOf(
          "Your FAFSA was received"
        ) != -1
      ) {
        setHeader("FAFSA Received");
        setMessage(
          `Thank you for filing your FAFSA for ${Desc}. We have received your FAFSA and you can review your Financial Aid Status`
        );
        setShowInfo(true);
        setShowlink(false);
      } else {
        const UpComing = parseInt(Year);
        const Current = UpComing - 1;
        showMessage(Current, UpComing);
      }
    } catch (error) {
      console.error("Error fetching financial aid year:", error);
      return null;
    }
  };

  useEffect(() => {
    // Fetch financial aid year data and update the message based on the user's FAFSA status
    getFinancialAidYear();
  }, []);

  // Generate message based on the user's financial aid status
  const generateFafsaMessage = (finAidYears: FinAidYear[]) => {
    const currentAidYear = new Date().getFullYear().toString(); // Current aid year
    const info = finAidYears.filter((year) => year.AidYear == currentAidYear);
    const infoForeYear = finAidYears
      .map((year) => parseInt(year.AidYear))
      .filter((year) => year > parseInt(currentAidYear))
      .sort((a, b) => b - a);
    //have previous aidyears but no current year
    if (info.length > 0 && infoForeYear.length == 0) {
      const currYear = parseInt(currentAidYear);
      const upComing = currYear + 1;
      setHeader("Important Cost-Savings Reminder");
      setSubHeader(
        "File Your New" +
          " " +
          currYear +
          " " +
          "-" +
          " " +
          upComing +
          " " +
          "FAFSA."
      );
      setMessage(
        `In order to qualify for applicable grants, scholarships, or 
        aid for upcoming classes, please submit the new ${currYear}-${upComing} FAFSA.`
      );
      setShowlink(true);
    }
    // have current aid year
    else if (info.length > 0 && infoForeYear.length > 0) {
      const ForeYearDescr = finAidYears.filter(
        (year) => year.AidYear == infoForeYear[0].toString()
      );
      getFinancialAidYearDesc(
        ForeYearDescr[0].AidYear,
        ForeYearDescr[0].AidYearDescr
      );
    } else {
      if (info.length == 0) {
        const ForeYear = finAidYears
          .map((year) => parseInt(year.AidYear))
          .filter((year) => year > parseInt(currentAidYear))
          .sort((a, b) => b - a);
        if (ForeYear.length == 0) {
          const pastYear = finAidYears
            .map((year) => parseInt(year.AidYear))
            .filter((year) => year < parseInt(currentAidYear))
            .sort((a, b) => b - a);
          if (pastYear.length > 0) {
            const desc = finAidYears.filter(
              (year) => year.AidYear == pastYear[0].toString()
            );
            const currYear = parseInt(currentAidYear);
            const upComing = currYear + 1;
            setHeader("Important Cost-Savings Reminder");
            setSubHeader(
              "File Your New" +
                " " +
                currYear +
                " " +
                "-" +
                " " +
                upComing +
                " " +
                "FAFSA."
            );
            setMessage(
              `In order to qualify for applicable grants, scholarships, or aid for upcoming classes,
                  please submit the new ${currYear} - ${upComing} FAFSA. 
                 Submit the FAFSA today.`
            );
            setShowlink(true);
          } else {
            const currYear = parseInt(currentAidYear);
            const upComing = currYear + 1;
            showMessage(currYear, upComing);
          }
        } else {
          const ForeYearDescr = finAidYears.filter(
            (year) => year.AidYear == ForeYear[0].toString()
          );

          if (ForeYear.length > 0) {
            getFinancialAidYearDesc(
              ForeYearDescr[0].AidYear,
              ForeYearDescr[0].AidYearDescr
            );
            getFinancialDecisionData(ForeYearDescr[0].AidYear);
          }
        }
      }
    }
  };

  const showMessage = (currYear: number, upComing: number) => {
    setHeader("Important Cost-Savings Reminder");
    setSubHeader(
      "File Your " +
        " " +
        currYear +
        " " +
        "-" +
        " " +
        upComing +
        " " +
        "FAFSA."
    );
    setMessage(
      "<strong>All students should submit the FAFSA.</strong> Even if you have another plan to pay for school, it’s in your best interest to complete the FAFSA because you may qualify for grants and scholarships that do not need to be repaid. Even students accepting military benefits may qualify for additional federal benefits. The FAFSA is now faster and easier to fill out with fewer questions in 11 languages, and more students will be eligible. Depending on your circumstances, it could require as few as 18 questions and take less than 10 minutes to complete."
    );
    setShowlink(true);
  };

  return (
    <section
      aria-labelledby="Scholarships"
      className="border-box -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)] moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)] relative mb-[1.25rem] mr-[1.75rem] bg-[#ffffff] p-[1.25rem]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        width: "95%",
      }}
    >
      <div className="flex-justify-between">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
            }}
          >
            {header}
          </h3>
          <h3>{subheader}</h3>
        </div>
      </div>

      <br />

      {showlink && (
        <div className="flex-justify-between">
          <div className="text-left">
            <p
              className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]"
              dangerouslySetInnerHTML={{ __html: message }}
            />{" "}
            <br />
            <a
              id="submitFafsa"
              target="blank"
              href="https://studentaid.gov/h/apply-for-aid/fafsa"
              className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
            >
              Submit the FAFSA Today
            </a>{" "}
            using UMGC's school code 011644.
            <p></p>
          </div>
        </div>
      )}
      {showInfo && (
        <div className="flex-justify-between">
          <div className="text-left">
            <p className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]">
              {message}
              <a
                id="submitFafsa"
                target="blank"
                href="#"
                onClick={handleClick}
                className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
              >
                &nbsp;here.
              </a>{" "}
            </p>

            <Dialog.Root open={showDialog}>
              <ModalWrapper
                show={showDialog}
                className="scrollbar-hide !h-[240px] max-h-[80vh] w-[56%] max-w-[90vw]"
                ariaLabelledby="hFinText"
              >
                <div id="financialAid" style={{ overflow: "hidden" }}>
                  <div className="mb-6 flex justify-between">
                    <h2 className="text-[30px]" id="hFinText">
                      {text}
                    </h2>
                    <Dialog.Close
                      className="border-none text-3xl"
                      onClick={(e) => {
                        close(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") close(e);
                      }}
                      aria-label="Close"
                    >
                      &times;
                    </Dialog.Close>
                  </div>
                  <Paragraph>
                    When you are finished, you can continue your session by
                    returning to this tab.
                  </Paragraph>
                  <div
                    className="text-right"
                    style={{
                      marginTop: "1.875rem",
                      paddingTop: "1.875rem",
                      borderTop: "0.063rem solid rgba( 233, 233, 233, 1 )",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#fff",
                        border: "solid rgba(253,191,56,1) 0.063rem",
                        color: "#000",
                        padding: "0.625rem 1.25rem",
                        fontWeight: "700",
                        fontSize: "0.9375rem",
                      }}
                      onClick={close}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") close(e);
                      }}
                    >
                      Cancel
                    </button>
                    &nbsp;&nbsp;
                    {urlHostName === urlQAT && (
                      <button
                        style={{
                          backgroundColor: "rgba(253,191,56,1)",
                          border: "solid rgba(253,191,56,1) 0.063rem",
                          color: "#000",
                          padding: "0.625rem 1.25rem",
                          fontWeight: "700",
                          fontSize: "0.9375rem",
                        }}
                        id="okBtnFA"
                        onClick={() => OK(null, portalQAT)}
                      >
                        Ok
                      </button>
                    )}
                    {urlHostName === urlSTG && (
                      <button
                        style={{
                          backgroundColor: "rgba(253,191,56,1)",
                          border: "solid rgba(253,191,56,1) 0.063rem",
                          color: "#000",
                          padding: "0.625rem 1.25rem",
                          fontWeight: "700",
                          fontSize: "0.9375rem",
                        }}
                        id="okBtnFA"
                        onClick={() => OK(null, portalSTG)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") OK(e, portalSTG);
                        }}
                      >
                        Ok
                      </button>
                    )}
                    {urlHostName === urlPROD && (
                      <button
                        style={{
                          backgroundColor: "rgba(253,191,56,1)",
                          border: "solid rgba(253,191,56,1) 0.063rem",
                          color: "#000",
                          padding: "0.625rem 1.25rem",
                          fontWeight: "700",
                          fontSize: "0.9375rem",
                        }}
                        id="okBtnFA"
                        onClick={() => OK(null, portalPROD)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") OK(e, portalPROD);
                        }}
                      >
                        Ok
                      </button>
                    )}
                  </div>
                </div>
              </ModalWrapper>
            </Dialog.Root>
          </div>
        </div>
      )}
    </section>
  );
};

angularize(FafsaStatus, "fafsaStatusReact", angular.module("duApp"), {});

export default FafsaStatus;
