import clsx from "clsx";
import ChevronLeft from "icons/ChevronLeft";
import ChevronRight from "icons/ChevronRight";
import React, { useState } from "react";
import { paginate } from "utils/pagination";

interface PaginationProps {
  count: number;
  pageSize: number;
  currentPage?: number;
}

const Pagination = ({
  count,
  currentPage: currentPageProp,
  pageSize: pageSizeProp,
}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(
    currentPageProp ? currentPageProp : 1
  );
  const [pageSize, setPageSize] = useState(pageSizeProp);

  const firstPage = 1;
  const lastPage =
    Math.ceil(count / pageSize) === 0 ? 1 : Math.ceil(count / pageSize);

  return {
    currentPage,
    setCurrentPage: (value: number) => {
      setCurrentPage(Math.min(Math.max(value, firstPage), lastPage));
    },
    pageSize,
    PaginationComponent: () => (
      <div className="flex items-center gap-2">
        <div className="flex flex-row items-center gap-2 text-sm">
          <button
            disabled={currentPage === firstPage}
            onClick={() => setCurrentPage(currentPage - 1)}
            className={clsx(
              "w-3 border-none",
              currentPage === firstPage
                ? "cursor-not-allowed"
                : "cursor-pointer"
            )}
            aria-label="Previous"
          >
            <ChevronLeft />
          </button>
          <div>
            <div className="flex">
              {paginate(currentPage, lastPage).map((page, idx) =>
                typeof page === "number" ? (
                  <button
                    key={idx}
                    onClick={() => setCurrentPage(page)}
                    className={clsx(
                      "cursor-pointer border-none p-2 text-du-blue",
                      page === currentPage && "underline"
                    )}
                  >
                    {page}
                  </button>
                ) : (
                  <span key={idx} className={"select-none p-2 text-du-blue"}>
                    {page}
                  </span>
                )
              )}
            </div>
          </div>
          <button
            disabled={currentPage === lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
            className={clsx(
              "w-3 border-none",
              currentPage === lastPage ? "cursor-not-allowed" : "cursor-pointer"
            )}
            aria-label="Next"
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    ),
  };
};

export default Pagination;
