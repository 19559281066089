import clsx from "clsx";
import { DateTime } from "luxon";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { SemesterUI } from "./StartDateField";
import StartDateList from "./StartDateList";

interface SemesterMenuProps {
  setIsMenuOpen: (value: boolean) => void;
  semesterUIList: SemesterUI[];
  setSemesterUIList: React.Dispatch<React.SetStateAction<SemesterUI[]>>;
}

const SemesterMenu: React.FC<SemesterMenuProps> = ({
  setIsMenuOpen,
  semesterUIList,
  setSemesterUIList,
}) => {
  const { dispatch } = useContext(ClassSearchContext);
  const divRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(divRef, () => {
    setIsMenuOpen(false);
  });

  return (
    <div ref={divRef} className="relative z-[1]">
      <div className="absolute flex min-h-[150px] w-auto flex-col overflow-hidden rounded-md border bg-white shadow-md sm:w-[440px] sm:flex-row">
        <div className="w-auto sm:w-[55%]">
          <p className="cursor-pointer px-4 py-2 font-bold text-[#5187BF]">
            Terms
          </p>
          {semesterUIList.length === 0 ? (
            <div className="px-4 py-2">
              There are currently no scheduled courses for your program. Please
              contact an advisor for assistance.
            </div>
          ) : (
            <ul role="tablist">
              {semesterUIList.map((item) => (
                <li
                  key={item.semesterCode}
                  role="presentation"
                  className={clsx(
                    "cursor-pointer select-none !py-2 px-4",
                    item.isSelected && "bg-[#FEFAEB]"
                  )}
                >
                  <button
                    role="tab"
                    aria-selected={item.isSelected ? "true" : "false"}
                    aria-controls={`tabpanel-${item.semesterCode}`}
                    id={`tab-${item.semesterCode}`}
                    className="w-full text-left"
                    onClick={() => {
                      setSemesterUIList((prev) =>
                        prev.map((semesterUI) => ({
                          ...semesterUI,
                          isSelected:
                            semesterUI.semesterCode === item.semesterCode,
                        }))
                      );
                    }}
                  >
                    <p className="font-bold">{item.semesterName}</p>
                    <p className="font-semibold">
                      {DateTime.fromISO(item.semesterStartDate).toFormat(
                        "MMMM d"
                      )}
                      {" - "}
                      {DateTime.fromISO(item.semesterEndDate).toFormat(
                        "MMMM d"
                      )}
                    </p>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className="w-auto bg-[#FEFAEB] sm:w-[45%]"
          role="tabpanel"
          id={`tabpanel-${
            semesterUIList.find((item) => item.isSelected)?.semesterCode
          }`}
          aria-labelledby={`tab-${
            semesterUIList.find((item) => item.isSelected)?.semesterCode
          }`}
        >
          <p className="px-4 py-2 pb-0 font-bold text-[#5187BF]">Start Dates</p>
          <button
            id="cs-start-dates-select-all"
            className={clsx(
              "px-4 text-sm text-[#5187BF] hover:underline",
              semesterUIList.length === 0 && "hidden"
            )}
            onClick={() => {
              setSemesterUIList((prev) => {
                const newSemesterUIList = prev.map((item) => {
                  if (item.isSelected) {
                    item.startDates = item.startDates.map((startDate) => {
                      startDate.isSelected = true;
                      return startDate;
                    });
                  }
                  return item;
                });

                const sessionStartDateList = newSemesterUIList
                  .flatMap((semesterUI) => semesterUI.startDates)
                  .filter((startDate) => startDate.isSelected)
                  .map((startDate) => startDate.startDate);

                dispatch({
                  type: "UPDATE_SESSION_START_DATE_LIST",
                  payload: {
                    sessionStartDateList,
                  },
                });

                return newSemesterUIList;
              });
            }}
          >
            Select All
          </button>
          <div className="flex h-[80%] flex-col justify-between">
            {semesterUIList.map((item) => (
              <div
                hidden={!item.isSelected}
                key={item.semesterCode}
                className="p-4"
              >
                <StartDateList
                  semesterUIList={semesterUIList}
                  setSemesterUIList={setSemesterUIList}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemesterMenu;
