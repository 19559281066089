import { resetAllEventChannel } from "pages/newClassSearch/events/resetAllEventMsg";
import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";

import { ClassSearchContext } from "../../classSearchContext";
import {
  EField,
  fetchValues,
  IAND,
  IField,
  IFilter,
  IOR,
} from "../../classSearchQuery";
import { ClassSearchResponse } from "../../types";

interface SubjectItem {
  subjectCode: string;
  subjectName: string;
}

const SubjectField = () => {
  const [subjects, setSubjects] = useState<SubjectItem[]>([]);
  const [selectCode, setSelectCode] = useState(String(new Date().getTime()));

  const {
    state: {
      profile: { academicCareerCodeList },
      fields: {
        campusCode,
        academicCareerCode,
        sessionStartDateList,
        locationCodeList,
        classFormatCodeList,
      },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const fetchSubjects = async () => {
    try {
      const getFieldList = (onlineOnly: boolean) => {
        const fieldList: (IAND | IOR | IField)[] = [];

        let _campusCode = campusCode;
        if (onlineOnly) _campusCode = "ADLPH";
        fieldList.push({
          campusCode: {
            equals: _campusCode,
          },
        });

        if (
          academicCareerCodeList.length > 1 &&
          academicCareerCode === "UGRD"
        ) {
          fieldList.push({
            OR: [
              {
                academicCareerCode: {
                  equals: "UGRD",
                },
              },
              {
                academicCareerCode: {
                  equals: "USAL",
                },
              },
            ],
          });
        } else {
          fieldList.push({
            academicCareerCode: {
              equals: academicCareerCode,
            },
          });
        }

        if (sessionStartDateList.length > 0) {
          fieldList.push({
            OR: sessionStartDateList.map((startDate) => ({
              sessionStartDate: {
                equals: startDate,
              },
            })),
          });
        }

        let _locationCodeList = locationCodeList;
        if (onlineOnly) _locationCodeList = ["WEB"];
        if (locationCodeList.length > 0) {
          fieldList.push({
            OR: _locationCodeList.map((locationCode) => ({
              locationCode: {
                equals: locationCode,
              },
            })),
          });
        }

        return fieldList;
      };

      let filter: IFilter;

      if (
        campusCode !== "ADLPH" &&
        (locationCodeList.includes("WEB") || classFormatCodeList.length === 0)
      ) {
        filter = {
          OR: [
            {
              AND: getFieldList(false),
            },
            {
              AND: getFieldList(true),
            },
          ],
        };
      } else {
        filter = {
          AND: getFieldList(false),
        };
      }

      const classSearchPagination = (await fetchValues(
        {
          filter: filter,
          select: [EField.subjectCode, EField.subjectName],
          orderby: {
            subjectCode: "asc",
          },
        },
        "value",
        true
      )) as ClassSearchResponse;

      const subjects: SubjectItem[] = [
        {
          subjectCode: "",
          subjectName: "All",
        },
      ];
      subjects.push(
        ...classSearchPagination.value.map((item) => ({
          subjectCode: item.subjectCode,
          subjectName: item.subjectName,
        }))
      );

      setSubjects(subjects);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (campusCode !== "" && academicCareerCode !== "") {
      fetchSubjects();
    }
  }, [campusCode, academicCareerCode, sessionStartDateList, locationCodeList]);

  useEffect(() => {
    const unsubscribeOnResetAll = resetAllEventChannel.on("onResetAll", () => {
      setSelectCode(String(new Date().getTime()));
    });

    return () => {
      unsubscribeOnResetAll();
    };
  }, []);

  return (
    <div className="inline-block rounded-md bg-du-light-gray px-4 py-2">
      <div className="mb-2 flex justify-between">
        <p className="text-sm font-bold">Subject</p>
      </div>
      <div>
        <ReactSelect
          id="cs-subject-select-field"
          key={`ubject-select-field-${selectCode}`}
          name="subject-select-field"
          options={subjects}
          onChange={(value) => {
            const newSubjectCode = (value as SubjectItem)?.subjectCode;
            if (newSubjectCode === undefined) return;
            dispatch({
              type: "UPDATE_SUBJECT_CODE",
              payload: {
                subjectCode: newSubjectCode,
              },
            });
          }}
          getOptionLabel={(option) => {
            if (option.subjectCode === "") {
              return option.subjectName;
            }

            return `${(option as SubjectItem).subjectCode} - ${
              (option as SubjectItem).subjectName
            }`;
          }}
          getOptionValue={(option) => (option as SubjectItem).subjectCode}
          defaultValue={{
            subjectCode: "",
            subjectName: "All",
          }}
        />
      </div>
    </div>
  );
};

export default SubjectField;
